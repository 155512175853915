document.addEventListener('turbolinks:load', () => {
  const deleteButton = document.querySelector(".notification .delete");

  if (!deleteButton) {
    return;
  }

  deleteButton.addEventListener("click", () => {
    const notification = document.querySelector(".notification");
    notification.parentNode.removeChild(notification);
  });
});
