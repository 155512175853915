import autocomplete from 'autocompleter';

document.addEventListener('turbolinks:load', () => {
  const inputs = document.querySelectorAll('.autocompleter');

  for (let input of inputs) {
    input.autocomplete = "off";

    const suggestionsData = JSON.parse(input.dataset.suggestions);
    const suggestions = Object.keys(suggestionsData).map((value) => {
      return { value: value, label: suggestionsData[value] };
    });

    autocomplete(
      {
        input: input,
        minLength: 1,
        preventSubmit: true,
        fetch: (text, update) => {
          const matches = suggestions.filter(s => {
            return s.label.toLowerCase().match(text.toLowerCase());
          });
          update(matches);
        },
        onSelect: (item) => {
          input.value = item.value;
        },
        render: (item, value) => {
          const itemElement = document.createElement("div");
          itemElement.textContent = item.label;
          return itemElement;
        },
      }
    );
  }
});
