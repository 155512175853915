document.addEventListener('turbolinks:load', () => {
  const navbarButton = document.querySelector(".navbar-burger.burger");

  if (!navbarButton) {
    return;
  }

  navbarButton.addEventListener("click", () => {
    navbarButton.classList.toggle("is-active");

    const navbarMenu = document.querySelector(".navbar-menu");
    navbarMenu.classList.toggle("is-active");
  });
});
