document.addEventListener('turbolinks:load', () => {
  const htmlContent = document.querySelector("[data-target='job-input-html-content']");
  const inputContent = document.querySelector("#input_content");

  if (!htmlContent || !inputContent) {
    return;
  }

  htmlContent.addEventListener('paste', (event) => {
    let paste = (event.clipboardData || window.clipboardData).getData('text/html');

    // save html in hidden field
    inputContent.value += paste;

    if (!paste) {
      return;
    }

    // show html for UI feedback
    const selection = window.getSelection();
    if (!selection.rangeCount) return false;

    htmlContent.innerHTML += paste;

    event.preventDefault();
  });
});
